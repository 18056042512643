import { useState } from 'react';

export function useFilter() {
  const [ratingFilter, setRatingFilter] = useState(1);
  const [decadeFilter, setDecadeFilter] = useState('all');

  const applyRatingFilter = (items) => {
    return items.filter((item) => item.vote_average >= ratingFilter);
  };

  const applyDecadeFilter = (items) => {
    if (decadeFilter === 'all') {
      return items;
    }

    const startYear = parseInt(decadeFilter);
    const endYear = startYear + 9;

    return items.filter((item) => {
      const releaseYear = item.release_date
        ? parseInt(item.release_date.slice(0, 4))
        : parseInt(item.first_air_date.slice(0, 4));
      return releaseYear >= startYear && releaseYear <= endYear;
    });
  };

  const applyFilters = (items) => {
    let filteredItems = applyRatingFilter(items);
    filteredItems = applyDecadeFilter(filteredItems);
    return filteredItems;
  };

  return {
    ratingFilter,
    setRatingFilter,
    decadeFilter,
    setDecadeFilter,
    applyFilters,
  };
}
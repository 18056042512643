// In src/components/filters/DecadeFilter.js
import React from 'react';

const DecadeFilter = ({ decadeFilter, setDecadeFilter }) => {
  const handleDecadeChange = (event) => {
    setDecadeFilter(event.target.value);
  };

  return (
    <div className="decade-filter">
      <label htmlFor="decade-filter">Decade:</label>
      <select
        id="decade-filter"
        value={decadeFilter}
        onChange={handleDecadeChange}
      >
        <option value="all">All</option>
        <option value="1970">1970s</option>
        <option value="1980">1980s</option>
        <option value="1990">1990s</option>
        <option value="2000">2000s</option>
        <option value="2010">2010s</option>
        <option value="2020">2020s</option>
      </select>
    </div>
  );
};

export default DecadeFilter;
import React from 'react';

const RatingFilter = ({ ratingFilter, setRatingFilter }) => {
  const handleRatingChange = (event) => {
    setRatingFilter(Number(event.target.value));
  };

  return (
    <div className="rating-filter">
      <label htmlFor="rating-filter">Rating:</label>
      <select
        id="rating-filter"
        value={ratingFilter}
        onChange={handleRatingChange}
      >
        <option value="1">1+</option>
        <option value="2">2+</option>
        <option value="3">3+</option>
        <option value="4">4+</option>
        <option value="5">5+</option>
        <option value="6">6+</option>
        <option value="7">7+</option>
        <option value="8">8+</option>
      </select>
    </div>
  );
};

export default RatingFilter;
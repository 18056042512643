import React from 'react';
import { FaTimes } from 'react-icons/fa';

function SearchBar({ searchQuery, setSearchQuery, errorMessage, handleSearch }) {
  const searchInputRef = React.useRef(null);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
      searchInputRef.current.blur(); // blur the input to hide the keyboard
    }
  };

  const handleClear = () => {
    setSearchQuery('');
    searchInputRef.current.focus(); // focus back on the input
  };

  return (
    <div className="search-bar-container">
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <div className="search-input-container">
        <input
          ref={searchInputRef}
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Search for a movie or TV show"
        />
        {searchQuery && (
          <p className="clear-search-button" onClick={handleClear}>
            <FaTimes />
          </p>
        )}
      </div>
    </div>
  );
}

export default SearchBar;
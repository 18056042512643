import React from 'react';
import ReactPlayer from 'react-player';

function TrailerOverlay({ trailerURL, closeTrailerOverlay }) {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={closeTrailerOverlay}
    >
      <ReactPlayer
        url={trailerURL}
        playing={true}
        width="80%"
        height="80%"
      />
    </div>
  );
}

export default TrailerOverlay;
import React from 'react';

function SearchResult({ item, openTrailerOverlay }) {
  const renderStreamingProviders = () => {
    if (item.streamingProviders && item.streamingProviders.length > 0) {
      return item.streamingProviders.map((provider) => provider.provider_name).join(', ');
    }
    return 'Not Streaming';
  };

  return (
    <div className="result" key={item.id}>
      <img
        src={`https://image.tmdb.org/t/p/w200${item.poster_path}`}
        alt={item.title || item.name}
      />
      <div className="result-content">
        <h2>
        <a
  href={item.imdbURL}
  target="_blank"
  rel="noopener noreferrer"
>
  {item.title || item.name}
</a>
        </h2>
        <p>Rating: {item.vote_average}</p>
        <p>Streaming on: {renderStreamingProviders()}</p>
        <button className="watch-trailer-button" onClick={() => openTrailerOverlay(item.trailer)}>
          Trailer
        </button>
      </div>
    </div>
  );
}

export default SearchResult;
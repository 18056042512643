import axios from 'axios';

const BACKEND_BASE_URL = 'https://weup1lmf01func01.azurewebsites.net/api';
const API_BASE_URL = 'https://api.themoviedb.org/3';
const API_KEY = process.env.REACT_APP_TMDB_API_KEY;
const FUNCTION_KEY_MOVIE = process.env.REACT_APP_LMF_MOVIE_FUNCTION_KEY;
const FUNCTION_KEY_TV = process.env.REACT_APP_LMF_TV_FUNCTION_KEY;

export const instance = axios.create({
  baseURL: BACKEND_BASE_URL,
});

export const instanceapi = axios.create({
  baseURL: API_BASE_URL,
});

export const searchMovieOrTVShow = async (query) => {
  const response = await instanceapi.get('/search/multi', {
    params: {
      api_key: API_KEY,
      query: query,
      page: 1,
    },
  });

  return response.data.results;
};

export const getIMDbId = async (id, mediaType) => {
  const response = await instanceapi.get(`/${mediaType}/${id}/external_ids`, {
    params: {
      api_key: API_KEY,
    },
  });

  return response.data.imdb_id;
};

export const getStreamingInfo = async (id, mediaType) => {
  const response = await instanceapi.get(`/${mediaType}/${id}/watch/providers`, {
    params: {
      api_key: API_KEY,
    },
  });

  const providers = response.data.results.NL;

  if (providers) {
    return {
      buy: providers.buy,
      flatrate: providers.flatrate,
      rent: providers.rent,
    };
  }
  return null;
};

export const getTrailer = async (id, mediaType) => {
  const response = await instanceapi.get(`/${mediaType}/${id}/videos`, {
    params: {
      api_key: API_KEY,
    },
  });

  const trailers = response.data.results.filter(
    (video) =>
      video.type.toLowerCase() === 'trailer' &&
      video.site.toLowerCase() === 'youtube'
  );

  trailers.sort((a, b) => {
    const aRelevanceScore = a.name.toLowerCase().includes("official") ? 1 : 0;
    const bRelevanceScore = b.name.toLowerCase().includes("official") ? 1 : 0;
    return bRelevanceScore - aRelevanceScore;
  });

  return trailers.length > 0 ? trailers[0] : null;
};

export const getPopularMovies = async () => {
  try {
    const response = await instance.get("/GetPopularMovies", {
      params: {
        code: FUNCTION_KEY_MOVIE,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching popular movies:", error);
    return [];
  }
};

export const getPopularTVShows = async () => {
  try {
    const response = await instance.get("/GetPopularTVShows", {
      params: {
        code: FUNCTION_KEY_TV,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching popular TV shows:", error);
    return [];
  }
};
import { useState } from 'react';
import { searchMovieOrTVShow, getStreamingInfo, getTrailer, getIMDbId } from '../services/api';

export function useSearch() {
  const [searchQuery, setSearchQuery] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const getDetailedResults = async (items) => {
    const detailedResults = await Promise.all(
      items.map(async (item) => {
        if (!["movie", "tv"].includes(item.media_type)) {
          return null;
        }
        const mediaType = item.media_type === 'movie' ? 'movie' : 'tv';
        const imdbId = await getIMDbId(item.id, mediaType);

        const streamingInfoPromise = getStreamingInfo(item.id, mediaType);
        const trailerPromise = getTrailer(item.id, mediaType);

        const [streamingInfo, trailer] = await Promise.all([
          streamingInfoPromise,
          trailerPromise,
        ]);

        if (streamingInfo && streamingInfo.flatrate) {
          return {
            ...item,
            mediaType,
            imdbId,
            imdbURL: `https://www.imdb.com/title/${imdbId}`,
            streamingProviders: streamingInfo.flatrate.length > 0 ? streamingInfo.flatrate : [],
            trailer: trailer ? trailer.key : null,
            tmdbURL: `https://www.themoviedb.org/${mediaType}/${item.id}`,
          };
        } else if (streamingInfo === null) {
          return {
            ...item,
            mediaType,
            streamingProviders: [],
            trailer: trailer ? trailer.key : null,
            tmdbURL: `https://www.themoviedb.org/${mediaType}/${item.id}`,
          };
        }
        return null;
      })
    );
    return detailedResults.filter((result) => result !== null);
  };

  const handleSearch = async (setResults, setLoading) => {
    if (!searchQuery) {
      setErrorMessage('Please enter a search query.');
      return;
    }

    setErrorMessage('');
    setLoading(true);

    const searchResults = await searchMovieOrTVShow(searchQuery);
    console.log('searchResults:', searchResults);

    if (searchResults.length === 0) {
      setLoading(false);
      setErrorMessage('No results found.');
      return;
    }

    const filteredResults = searchResults.slice(0, 10);
    const detailedResults = await getDetailedResults(filteredResults);

    setResults(detailedResults.slice(0, 6));
    setLoading(false);
  };

  return {
    searchQuery,
    setSearchQuery,
    errorMessage,
    setErrorMessage,
    handleSearch,
    getDetailedResults,
  };
}
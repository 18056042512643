import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

function ButtonGroup({ handleSearch, loading, handlePopularMovies, handlePopularTVShows, randomLoading }) {
  return (
    <div className='buttons'>
      {randomLoading ? (
        <div className="overlay">
          <div className="loader">
            <ThreeDots color="#624CAB" height={40} width={40} />
          </div>
        </div>
      ) : (
        <>
          <button onClick={handleSearch} disabled={loading}>
            {loading ? 'Searching...' : 'Search'}
          </button>
          <button onClick={handlePopularMovies}>Random Movies</button>
          <button onClick={handlePopularTVShows}>Random Shows</button>
        </>
      )}
    </div>
  );
}

export default ButtonGroup;
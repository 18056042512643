import { useMemo } from 'react';
import { getStreamingInfo, getTrailer, getPopularMovies, getPopularTVShows, getIMDbId } from '../services/api';

export function usePopular() {
  const getRandomResults = useMemo(() => {
    return (resultsArray, count) => {
      const shuffled = resultsArray.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, count);
    };
  }, []);

  const getDetailedResults = async (items, mediaType) => {
    const excludedProviders = ['Crunchyroll'];

    const results = await Promise.all(items.map(async (item) => {
      const streamingInfo = await getStreamingInfo(item.id, mediaType);
      const trailer = await getTrailer(item.id, mediaType);
      const imdbId = await getIMDbId(item.id, mediaType);
  
      if (streamingInfo && streamingInfo.flatrate && streamingInfo.flatrate.length > 0) {
        
        const filteredProviders = streamingInfo.flatrate.filter(
          (provider) => !excludedProviders.includes(provider.provider_name)
        );

        if (filteredProviders.length > 0) {
          return {
            ...item,
            mediaType,
            imdbId,
            imdbURL: `https://www.imdb.com/title/${imdbId}`,
            streamingProviders: streamingInfo.flatrate,
            trailer: trailer ? trailer.key : null,
            tmdbURL: `https://www.themoviedb.org/${mediaType}/${item.id}`,
         };
        }
     }
      return null;
    }));
    return results.filter(result => result !== null);
  };  

  const excludedGenreIds = [99, 10770];

  const filterExcludedGenres = (items) => {
    return items.filter(item => !item.genre_ids.some(genreId => excludedGenreIds.includes(genreId)));
  };
  

  const handlePopularMovies = async (applyFilters) => {
    const popularMovies = await getPopularMovies();
    const filteredByGenre = filterExcludedGenres(popularMovies);
    const filteredMovies = applyFilters(filteredByGenre, true);
    const randomMovies = getRandomResults(filteredMovies, 50);
    const resultsWithStreamingAndTrailer = await getDetailedResults(randomMovies, "movie");
    return resultsWithStreamingAndTrailer.slice(0, 6);
  };

  const handlePopularTVShows = async (applyFilters) => {
    const popularTVShows = await getPopularTVShows();
    const filteredTVShows = applyFilters(popularTVShows);
    const randomTVShows = getRandomResults(filteredTVShows, 50);
    const resultsWithStreamingAndTrailer = await getDetailedResults(randomTVShows, "tv");
    return resultsWithStreamingAndTrailer.slice(0, 6);
  };

  return {
    getRandomResults,
    handlePopularMovies,
    handlePopularTVShows,
    getDetailedResults,
  };
}
import React from 'react';

import './App.css';

import Header from './components/Header';
import SearchBar from './components/SearchBar';
import ButtonGroup from './components/ButtonGroup';
import SearchResult from './components/SearchResult';
import TrailerOverlay from './components/TrailerOverlay';
import { useMovieAndTV } from './hooks/useMovieAndTV';
import RatingFilter from './components/filters/RatingFilter';
import DecadeFilter from './components/filters/DecadeFilter';

function App() {
  const {
    searchQuery,
    setSearchQuery,
    results,
    loading,
    showTrailerOverlay,
    trailerURL,
    errorMessage,
    handleSearch,
    handlePopularMovies,
    handlePopularTVShows,
    randomLoading,
    openTrailerOverlay,
    closeTrailerOverlay,
    ratingFilter,
    setRatingFilter,
    decadeFilter,
    setDecadeFilter
  } = useMovieAndTV();

  return (
    <div className="App">
      <Header />
      <SearchBar         
      handleSearch={handleSearch}
      searchQuery={searchQuery} 
      setSearchQuery={setSearchQuery} 
      errorMessage={errorMessage} 
      />
      <br></br>
      <div className='filters'>
      <RatingFilter
        ratingFilter={ratingFilter}
        setRatingFilter={setRatingFilter}
      />
      <DecadeFilter
      decadeFilter={decadeFilter}
      setDecadeFilter={setDecadeFilter}
      />
      </div>
      <br></br>
      <ButtonGroup
        handleSearch={handleSearch}
        loading={loading}
        handlePopularMovies={handlePopularMovies}
        handlePopularTVShows={handlePopularTVShows}
        randomLoading={randomLoading}
      />
      <div className="results">
        {results.map((item) => (
          <SearchResult key={item.id} item={item} openTrailerOverlay={openTrailerOverlay} />
        ))}
      </div>
      {showTrailerOverlay && <TrailerOverlay trailerURL={trailerURL} closeTrailerOverlay={closeTrailerOverlay} />}
    </div>
  );
}

export default App;
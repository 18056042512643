import { useState } from 'react';
import { useSearch } from './useSearch';
import { usePopular } from './usePopular';
import { useFilter } from './useFilter';

export function useMovieAndTV() {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [trailerURL, setTrailerURL] = useState(null);
  const [randomLoading, setRandomLoading] = useState(false);
  const [showTrailerOverlay, setShowTrailerOverlay] = useState(false);
  

  const {
    searchQuery,
    setSearchQuery,
    errorMessage,
    setErrorMessage,
    handleSearch: handleSearchFromHook,
  } = useSearch();

  const {
    handlePopularMovies: handlePopularMoviesFromHook,
    handlePopularTVShows: handlePopularTVShowsFromHook,
    getDetailedResults,
  } = usePopular();

  const {
    ratingFilter,
    setRatingFilter,
    decadeFilter,
    setDecadeFilter,
    applyFilters,
  } = useFilter();

  const handleSearchWrapper = async () => {
    await handleSearchFromHook(setResults, setLoading, getDetailedResults);
  };

  const handlePopularMovies = async () => {
    setRandomLoading(true);
    const popularMovies = await handlePopularMoviesFromHook(applyFilters);
    setResults(popularMovies);
    setRandomLoading(false);
  };

  const handlePopularTVShows = async () => {
    setRandomLoading(true);
    const popularTVShows = await handlePopularTVShowsFromHook(applyFilters);
    setResults(popularTVShows);
    setRandomLoading(false);
  };

  const openTrailerOverlay = (trailerKey) => {
    setTrailerURL(`https://www.youtube.com/watch?v=${trailerKey}`);
    setShowTrailerOverlay(true);
  };

  const closeTrailerOverlay = () => {
    setShowTrailerOverlay(false);
    setTrailerURL(null);
  };

  return {
    searchQuery,
    setSearchQuery,
    results,
    setResults,
    randomLoading,
    loading,
    setLoading,
    showTrailerOverlay,
    setShowTrailerOverlay,
    trailerURL,
    setTrailerURL,
    errorMessage,
    setErrorMessage,
    handleSearch: handleSearchWrapper,
    handlePopularMovies,
    handlePopularTVShows,
    openTrailerOverlay,
    closeTrailerOverlay,
    ratingFilter,
    setRatingFilter,
    decadeFilter,
    setDecadeFilter,
  };
}